<template>
  <div class="help">
    <div class="container flex flex-jsb flex flex-hw">
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="[
          'box flex',
          (index + 1) % 3 === 0 ? 'flex-jend' : '',
          index % 3 === 1 ? 'flex-hc' : '',
        ]"
      >
        <div class="helpGroup">
          <div class="helpTitle flex flex-vc">
            <img
              :src="'data:image/jpg;base64, ' + item.cate_logo"
              alt=""
            /><span>{{ item.cate_name }}</span>
          </div>
          <div
            class="helpItem flex"
            v-for="(it, idx) in item.articles"
            :key="index + idx"
            @click="goDetail(it)"
          >
            <span class="item-left"></span>
            <span>{{ it.name }}</span>
          </div>
        </div>
      </div>
      <div class="box"></div>
    </div>
  </div>
</template>
<script>
import api from "@/api";
export default {
  components: {},
  computed: {
    helpArr() {
      return this.$t("service.help.helpArr");
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      api.service
        .articleList()
        .then((res) => {
          // console.log(res);
          this.list = res.list;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    goDetail(item) {
      // console.log(item);
      // return;
      const { article_id } = item;
      this.$router.push({
        path: "/helpDetail",
        query: {
          article_id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.help {
  // padding-bottom: 60px;
  .box {
    width: 33.33%;
  }
  .helpGroup {
    padding-bottom: 60px;
    width: 200px;
    .helpTitle {
      font-size: 16px;
      font-weight: 600;
      color: var(--black);
    }
    img,
    .item-left {
      width: 40px;
      margin-right: 16px;
    }
    .helpItem {
      cursor: pointer;
      font-size: 14px;
      color: var(--blue);
      margin-top: 16px;
      // &:nth-child(2) {
      //   width: 200px;
      // }
    }
  }
}
</style>
