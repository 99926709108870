// let OSS = require("ali-oss");
import OSS from "ali-oss";
//oss下载文件
export async function downLoadFile(url, name) {
  // 区分链接还是id
  if (!url) {
    this.$message.error("下载地址有误");
    return;
  }
  url = decodeURIComponent(url);
  let id;
  if (url && url.split(".com/")[1]) {
    id = url.split(".com/")[1].split("?")[0].split("/")[1];
  } else {
    id = url;
  }
  const client = new OSS({
    accessKeyId: "LTAI5t5gyBPkgrFGyAkF6G4N",
    accessKeySecret: "4PEmadzUSyWS4QJcxsnejsoPxVngQm",
    bucket: "qkzq-public",
    region: "cn-shenzhen",
    endpoint: "oss-accelerate.aliyuncs.com",
  });
  // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
  if (name) {
    name = name + "." + id.split(".")[1];
  }
  const filename = name || id; // 自定义下载后的文件名。
  const response = {
    "content-disposition": `attachment; filename=${encodeURIComponent(
      filename
    )}`,
  };
  // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
  const downUrl = client.signatureUrl(`${id}`, { response });
  // window.location.href = downUrl;
  window.open(downUrl);
}
