<template>
  <div class="table">
    <div id="table-notice" class="container table-notice flex flex-vc flex-hc">
      {{ $t("common.bottomDesc") }}
      <span @click="show = !show">{{
        $t("business.securities.dealFeesLink")
      }}</span>
    </div>
    <div class="container">
      <el-collapse accordion v-model="activeNames">
        <el-collapse-item
          v-for="item in tableData"
          :key="item.cate_id"
          :name="item.cate_id"
        >
          <template slot="title">
            <div class="collapse-header flex flex-vc flex-jsb">
              <span>{{ item.cate_name }}</span>
              <img class="right-icon" src="@/assets/service/down.png" alt="" />
            </div>
          </template>
          <div
            class="table-item flex flex-vc flex-jsb"
            v-for="it in item.tables"
            :key="it.table_id"
          >
            <span>{{ it.name }}</span>
            <div class="table-item-right flex flex-vc" @click="downTable(it)">
              <a href="javascript:;">{{ $t("common.clickDown") }}</a
              ><img src="@/assets/service/download.png" alt="" />
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <model-box v-model="show" />
  </div>
</template>
<script>
import api from "@/api";
import { downLoadFile } from "@/utils/oss";
import ModelBox from "@/components/business/ModelBox.vue";
export default {
  components: {
    ModelBox,
  },
  data() {
    return {
      tableData: [],
      show: false,
      activeNames: "",
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    // window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // handleScroll() {
    //   var scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   var offsetTop = document.querySelector("#table-notice").offsetTop;
    //   if (scrollTop > offsetTop) {
    //     this.searchBarFixed = true;
    //   } else {
    //     this.searchBarFixed = false;
    //   }
    // },
    downTable({ file_id, name }) {
      downLoadFile(file_id, name);
    },
    getList() {
      api.service
        .tableList()
        .then((res) => {
          // console.log(res);
          this.tableData = res.list;
          if (this.tableData.length > 0)
            this.activeNames = this.tableData[0].cate_id;
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },
};
</script>
<style lang="scss" scoped>
.table {
  position: relative;
  padding-bottom: 60px;
  ::v-deep .el-collapse {
    border: none;
    .el-collapse-item {
      background: var(--whiteColor);
      padding: 0 32px;
      border-radius: 6px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
      margin-bottom: 8px;
      .el-collapse-item__header {
        height: 60px;
        border-color: transparent;
        font-size: 16px;
        font-weight: 600;
        color: var(--blackColor);
        .el-collapse-item__arrow {
          display: none;
        }
        &.is-active .right-icon {
          transform: rotate(180deg);
          margin-right: 3px;
        }
      }
      .el-collapse-item__content {
        padding-bottom: 0;
      }
      .el-collapse-item__wrap {
        border-bottom: none;
      }
    }
  }
  .collapse-header {
    width: 100%;
    .right-icon {
      width: 14px;
      height: 14px;
      transition: transform 0.3s, -webkit-transform 0.3s;
    }
  }
  .table-item {
    height: 60px;
    border-top: 1px solid var(--borderColor);
    font-size: 14px;
    color: var(--blackColor);
    &-right {
      cursor: pointer;
      a {
        color: var(--blue);
      }
      img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }
    }
    // transition: transform .3s,-webkit-transform .3s;
  }
  .table-notice {
    height: 32px;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    background-color: var(--noticeBg);
    color: var(--blackColor);
    font-size: 12px;
    span {
      color: var(--blue);
      cursor: pointer;
      display: inline-block;
    }
  }
}
</style>
